import styled from "styled-components";
import { Button } from "../../../style/button";
import Input from "../../../style/input";

export const Wrapper = styled.div`
  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 800px;
    padding: 56px 36px;
  }
`;

export const LoginInputEmail = styled(Input)`
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const LoginInputPassword = styled(Input)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

export const NameInput = styled(Input)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

export const LoginButton = styled(Button)`
  margin-top: 34px;
  max-width: 268px;
`;

export const RegisterButton = styled(Button)`
  margin-top: 34px;
  max-width: 268px;
`;

export const CancelButton = styled(Button)`
  margin-top: 34px;
  max-width: 268px;
  background-color: #891515;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;
