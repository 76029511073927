import React, { FC } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import { Label, PhoneInputWrapper } from "./style";
import getLanguage from "../../utils/getLanguage";

interface InputPhoneProps {
  label?: string;
  onChange: (phoneNumber: string, parameters: Record<string, string>) => void;
  placeholder?: string;
  required?: string;
  value: string;
}

/*
  preferredCountries must be an array of country codes
  a list can be found here https://countrycode.org/

  for more information about the package
  https://www.npmjs.com/package/react-phone-input-2
*/

const InputPhone: FC<InputPhoneProps> = ({
  label,
  onChange,
  placeholder,
  required = false,
  value
}) => {
  const userLang = getLanguage();

  return (
    <>
      {label ? <Label>{`${label}${required ? "*" : ""}`}</Label> : null}
      <PhoneInputWrapper>
        <PhoneInput
          country={userLang != "fr" ? "gb" : "fr"}
          preferredCountries={["fr", "gb"]}
          onChange={onChange}
          placeholder={placeholder ? placeholder : "+33X XX XX XX XX"}
          value={value || "+33"}
          buttonClass="phone-input_button"
          inputClass="phone-input_input"
        />
      </PhoneInputWrapper>
    </>
  );
};

export default InputPhone;
