import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "./store";
import { AuthState } from "./store/auth/types";
import timeSlot from "./routes/timeslots";
import TimeslotEdit from "./routes/timeslotEdit";
import OperationAreas from "./routes/operationAreas";
import OperationAreaEdit from "./routes/operationAreasEdit";
import Login from "./routes/login";
import Orders from "./routes/orders";
import OrdersToday from "./routes/orders-today";
import OrdersTomorrow from "./routes/orders-tomorrow";
import Order from "./routes/order";
import OrderConfirmation from "./routes/order-confirmation";
import ServiceClasses from "./components/serviceclasses";
import ServiceClassesEdit from "./components/serviceclass-edit";
import availability from "./routes/availability";
import holidays from "./routes/holidays";
import HolidaysEdit from "./components/holidays-edit";
import AvailabilityEdit from "./components/availability-edit";
import { UserType } from "./store/user/types";
import DeliveryTask from "./routes/deliveryTask";
import Drivers from "./components/drivers";
import DriversEdit from "./components/drivers-edit";

interface AuthRoute extends RouteProps {
  auth: boolean;
  role: string;
  authorizedRoles: string[];
}

const AuthRoute = ({
  auth,
  role,
  authorizedRoles,
  ...otherProps
}: AuthRoute) => {
  if (auth && authorizedRoles.indexOf(role) >= 0) {
    return <Route {...otherProps} />;
  }

  return <Redirect to="/" />;
};

const Routes = ({ token, role }: AuthState) => {
  const auth = token !== "";
  const allRoles = [
    UserType.DRIVER.toString().toLowerCase(),
    UserType.FACILITY.toString().toLowerCase(),
    UserType.ADMIN.toString().toLowerCase()
  ];

  const adminRoleOnly = [UserType.ADMIN.toString().toLowerCase()];

  const facilityAndAdminRoles = [
    UserType.ADMIN.toString().toLowerCase(),
    UserType.FACILITY.toString().toLowerCase()
  ];

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <AuthRoute
        auth={auth}
        role={role}
        // authorizedRoles={facilityAndAdminRoles}
        authorizedRoles={allRoles}
        exact
        path="/orders"
        component={Orders}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={facilityAndAdminRoles}
        exact
        path="/orders/today"
        component={OrdersToday}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={facilityAndAdminRoles}
        exact
        path="/orders/tomorrow"
        component={OrdersTomorrow}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/operationAreas"
        component={OperationAreas}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/serviceClasses"
        component={ServiceClasses}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/service-classes/edit"
        component={ServiceClassesEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/service-classes/edit/:id"
        component={ServiceClassesEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/operationAreas/edit"
        component={OperationAreaEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/operationAreas/edit/:id"
        component={OperationAreaEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/timeslot/edit"
        component={TimeslotEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/timeslot/edit/:id"
        component={TimeslotEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/availability"
        component={availability}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/availability/edit"
        component={AvailabilityEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/availability/edit/:id"
        component={AvailabilityEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/holidays"
        component={holidays}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/holidays/edit"
        component={HolidaysEdit}
      />

      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/holidays/edit/:id"
        component={HolidaysEdit}
      />

      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/order/:id"
        component={Order}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/order/:id/confirmation"
        component={OrderConfirmation}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/timeslots"
        component={timeSlot}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/deliveryTask"
        component={DeliveryTask}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/drivers"
        component={Drivers}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/drivers/edit"
        component={DriversEdit}
      />
      <AuthRoute
        auth={auth}
        role={role}
        authorizedRoles={adminRoleOnly}
        exact
        path="/drivers/edit/:id"
        component={DriversEdit}
      />
    </Switch>
  );
};

const mapStateToProps = (state: AppState) => state.auth;

export default connect(mapStateToProps)(Routes);
