import React, { FC } from "react";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import { Input, InputContainer, InputSection } from "./style";
import usePickupDropOffRangePicker from "./use-pickup-drop-off-range-picker";
import LoadingSpinner from "../spinner/LoadingSpinner";

interface Props {
  isLoading?: boolean;
  onClick: () => void;
  reschedule?: boolean;
}

const PickupRangeInput: FC<Props> = ({
  isLoading = false,
  onClick,
  reschedule = false
}) => {
  const { loading, onPickupPress, dropOffRange } = usePickupDropOffRangePicker(
    reschedule
  );

  if (loading || isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <InputContainer>
      <InputSection style={{ marginRight: 15 }}>
        <Input
          onMouseDown={(e): void => e.preventDefault()}
          onClick={(): void => {
            onPickupPress();
            onClick();
          }}
        >
          <option>
            {dropOffRange &&
              dayjs(dropOffRange.startDate).format("dddd D MMMM")}
          </option>
        </Input>
      </InputSection>
      <InputSection>
        <Input
          onMouseDown={(e): void => e.preventDefault()}
          onClick={(): void => {
            onPickupPress();
            onClick();
          }}
        >
          <option>
            {dropOffRange &&
              `${dayjs(dropOffRange.startDate).format("HH:mm")} - ${dayjs(
                dropOffRange.endDate
              ).format("HH:mm")}`}
          </option>
        </Input>
      </InputSection>
    </InputContainer>
  );
};

export default PickupRangeInput;
