import {
  UserActionTypes,
  User,
  UserType,
  SET_USER,
  REMOVE_USER,
  UserState,
  SET_USERS
} from "./types";

const initialState: UserState = {
  email: "",
  id: "",
  name: "",
  type: UserType.NULL,
  drivers: []
};

const userReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        email: action.email,
        id: action.id,
        name: action.name,
        type: action.userType
      };
    }
    case REMOVE_USER: {
      return initialState;
    }
    case SET_USERS:
      return {
        ...state,
        drivers: action.users
      };
    default:
      return state;
  }
};

export default userReducer;
