const getLanguage = (): string => {
  let userLang = "fr";
  if (
    typeof window !== "undefined" &&
    ["fr", "en", "fr-FR", "en-US", "en-GB"].includes(navigator.language)
  ) {
    userLang = ["fr", "fr-FR"].includes(navigator.language) ? "fr" : "en";
  }

  return userLang;
};

export default getLanguage;
