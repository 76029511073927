import {
  OrdersActionTypes,
  OrdersState,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_BAG,
  CLEAR_SEARCHED,
  CLEAR_SEARCHED_ORDER,
  SET_BAG_TYPE,
  SET_PAGE,
  SET_TOTAL_PAGES,
  SET_CURRENT_BAG,
  SET_ORDERS,
  ADD_ORDERS,
  SET_NEW_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  SET_SEARCHED_ORDER,
  SET_ORDER_FILTER_DATES,
  SET_PICKUP_RANGE,
  SET_DROP_OFF_RANGE,
  SET_OPTIONS,
  SET_CURRENT_OPTION
} from "./types";

const initialState: OrdersState = {
  currentBagBarcode: null,
  currentBagType: null,
  currentOrder: null,
  orders: [],
  options: [],
  searched: false,
  itemization: {},
  page: 1,
  totalPages: 1,
  filterDates: ""
};

const ordersReducer = (
  state = initialState,
  action: OrdersActionTypes
): OrdersState => {
  switch (action.type) {
    case ADD_PRODUCT: {
      const existingQtyInc = action.quantity + 1;
      const productQty =
        action.productId in state.itemization
          ? state.itemization[action.productId] + 1
          : 1;

      return {
        ...state,
        itemization: {
          ...state.itemization,
          [action.productId]:
            productQty > existingQtyInc ? productQty : existingQtyInc
        }
      };
    }
    case REMOVE_PRODUCT: {
      const existingQtyDec = action.quantity - 1;
      const existingQty =
        action.productId in state.itemization
          ? state.itemization[action.productId]
          : 0;
      const productQty =
        existingQtyDec > existingQty
          ? existingQtyDec - 1
          : existingQty > 0
          ? existingQty - 1
          : 0;
      if (productQty === 0) {
        return {
          ...state,
          itemization: Object.keys(state.itemization).reduce((acc, key) => {
            if (key !== action.productId) {
              return { ...acc, [key]: state.itemization[key] };
            }
            return acc;
          }, {})
        };
      }
      return {
        ...state,
        itemization: { ...state.itemization, [action.productId]: productQty }
      };
    }
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.orders
      };
    }
    case ADD_ORDERS: {
      return {
        ...state,
        orders: [...state.orders, ...action.orders]
      };
    }
    case SET_ORDER_FILTER_DATES: {
      return {
        ...state,
        filterDates: action.filterDates
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }
    case SET_TOTAL_PAGES: {
      return {
        ...state,
        totalPages: action.page
      };
    }
    case SET_BAG_TYPE: {
      return {
        ...state,
        currentBagType: action.bagType
      };
    }
    case SET_CURRENT_BAG: {
      const itemization: { [key: string]: number } = {};

      for (let iterator = 0; iterator < action.itemization.length; iterator++) {
        itemization[action.itemization[iterator].product] =
          action.itemization[iterator].quantity;
      }

      return {
        ...state,
        currentBagBarcode: action.barcode,
        itemization: itemization
      };
    }
    case SET_NEW_COMMENT: {
      const orders = state.orders;
      const o = orders.find(o => o.id === action.orderId);

      if (!o) return { ...state };

      o.comments.push(action.newComment);

      return {
        ...state,
        ...(state.currentOrder &&
          state.currentOrder.id === o.id && {
            currentOrder: { ...state.currentOrder, comments: o.comments }
          }),
        orders: state.orders.map(order => {
          if (order.id !== o.id) return order;
          return {
            ...order,
            comments: o.comments
          };
        })
      };
    }
    case UPDATE_COMMENT: {
      const orders = state.orders;
      const o = orders.find(o => o.id === action.orderId);

      if (!o) return { ...state };
      o.comments = o.comments.map(comment => {
        if (comment.id !== action.comment.id) return comment;
        return action.comment;
      });

      return {
        ...state,
        ...(state.currentOrder &&
          state.currentOrder.id === o.id && {
            currentOrder: { ...state.currentOrder, comments: o.comments }
          }),
        orders: state.orders.map(order => {
          if (order.id !== o.id) return order;
          return {
            ...order,
            comments: o.comments
          };
        })
      };
    }
    case DELETE_COMMENT: {
      const orders = state.orders;
      const o = orders.find(o => o.id === action.orderId);
      if (!o) return { ...state };
      const commentIndex = o.comments.findIndex(
        comment => comment.id === action.commentId
      );
      o.comments.splice(commentIndex, 1);

      return {
        ...state,
        ...(state.currentOrder &&
          state.currentOrder.id === o.id && {
            currentOrder: { ...state.currentOrder, comments: o.comments }
          }),
        orders: state.orders.map(order => {
          if (order.id !== o.id) return order;
          return {
            ...order,
            comments: o.comments
          };
        })
      };
    }
    case SET_SEARCHED_ORDER: {
      return {
        ...state,
        currentOrder: action.order,
        searched: true
      };
    }
    case CLEAR_SEARCHED: {
      return {
        ...state,
        searched: false
      };
    }
    case CLEAR_BAG: {
      return {
        ...state,
        currentBagBarcode: null,
        itemization: {},
        currentBagType: null
      };
    }
    case CLEAR_SEARCHED_ORDER: {
      return {
        ...state,
        currentOrder: null,
        currentBagBarcode: null,
        itemization: {},
        currentBagType: null,
        searched: false
      };
    }
    case SET_PICKUP_RANGE: {
      console.log(
        ".... [SET_PICKUP_RANGE] action.pickupRange",
        action.pickupRange
      );
      const newState = {
        ...state,
        pickupRange: action.pickupRange
      };

      return {
        ...newState
      };
    }
    case SET_DROP_OFF_RANGE: {
      // console.log('.... [SET_DROP_OFF_RANGE]', action.dropOffRange)
      const newState = {
        ...state,
        dropOffRange: action.dropOffRange
      };

      return {
        ...newState
      };
    }
    case SET_OPTIONS: {
      return {
        ...state,
        options: action.options
      };
    }
    case SET_CURRENT_OPTION: {
      const newState = {
        ...state,
        currentOption: action.currentOption,
        pickupRange: undefined,
        dropOffRange: undefined
      };

      return {
        ...newState
      };
    }
    default:
      return state;
  }
};

export default ordersReducer;
