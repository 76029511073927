import { AppState } from "../../store";
import Cart from "./cart";
import OrderInformation from "./order-information";
import { ActionResponse, Option, OrderType } from "../../store/orders/types";
import PickupDropoffInfo from "./pickup-dropoff";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "qrcode.react";

import {
  Layout,
  LayoutContent,
  Button,
  ButtonContainer,
  QRCodeContainer,
  LeftContainer,
  RescheduleBtnContainer
} from "./style";
import usePickupDropOffRangePicker from "../pickup-drop-off-range-picker/use-pickup-drop-off-range-picker";
import PickupDropOffRangePickerModal from "../pickup-drop-off-range-picker/modal";
import PickupRangeInput from "../pickup-drop-off-range-picker/pickup-range-input";
import { getOptions, rescheduleOrderRequest } from "../../store/orders/actions";
import { Order } from "../../types/order";
import { useToast } from "../toast";
import LoadingSpinner from "../spinner/LoadingSpinner";

interface MatchParams {
  id: string;
}

const OrderConfirmation = ({
  match: {
    params: { id: orderId }
  }
}: RouteComponentProps<MatchParams>) => {
  const order = useSelector<AppState, OrderType | undefined>(state =>
    state.orders.orders.find(o => o.id === orderId)
  );

  const dispatch = useDispatch();
  const [requestLoading, setRequestLoading] = useState(false);
  const { showToast } = useToast();

  const {
    onDropOffPress,
    ...pickupRangePickerModalProps
  } = usePickupDropOffRangePicker(
    true,
    order
      ? ({
          id: order.id,
          pickupTime: order.pickupTime,
          dropoffTime: order.dropoffTime
        } as Order)
      : undefined
  );

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  const allOptions = useSelector<AppState, Option[]>(
    state => state.orders.options || []
  );

  const finishCleaningAction = useCallback(async () => {
    if (order) {
      setRequestLoading(true);
      const response = await dispatch(rescheduleOrderRequest(order));

      console.log("....response", response);

      if ((response as any).success) {
        showToast("OK");
      } else {
        showToast((response as any).error);
      }
      setRequestLoading(false);
    }
  }, [dispatch]);

  if (!order) return <span>Erreur lors du chargement de la commande</span>;

  return (
    <Layout title="Confirmation de commande" fixed>
      <LayoutContent>
        <LeftContainer>
          <ButtonContainer>
            <Button onClick={() => window.print()}>Imprimer</Button>
            <Button onClick={() => console.log("click")}>Linge prêt</Button>
          </ButtonContainer>
          <ButtonContainer>
            <PickupRangeInput
              isLoading={allOptions.length === 0}
              onClick={(): void => {
                if (onDropOffPress) onDropOffPress();
              }}
              reschedule
            />
            {/* <RescheduleBtnContainer> */}
            {requestLoading ? (
              <LoadingSpinner />
            ) : (
              <Button style={{ marginLeft: 10 }} onClick={finishCleaningAction}>
                Relavage
              </Button>
            )}
            {/* </RescheduleBtnContainer> */}
          </ButtonContainer>
        </LeftContainer>

        <QRCodeContainer>
          <QRCode value={window.location.href} size={200} />
        </QRCodeContainer>

        <OrderInformation order={order} />
        <PickupDropoffInfo order={order} />

        <Cart order={order} showPrice={false} />
      </LayoutContent>
      <PickupDropOffRangePickerModal {...pickupRangePickerModalProps} />
    </Layout>
  );
};

export default OrderConfirmation;
