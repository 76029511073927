import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Toggle from "../toggle";
import { UserType } from "../../store/user/types";
import { Response } from "../../store/auth/types";
import {
  Wrapper,
  LoginInputEmail,
  LoginInputPassword,
  LoginButton,
  RegisterButton,
  ButtonContainer,
  CancelButton,
  NameInput
} from "./style";
import { login as loginAction, Login } from "../../store/auth/actions";
import { History } from "history";

import { useToast, TYPE as TOAST_TYPE } from "../toast";
import InputPhone from "../../ui/input-phone-with-code-and-flag";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";
import { DriverUpdate } from "../../typings/driver";
import { registerDriver } from "../../store/drivers/actions";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";

interface LoginPage {
  loginAction: (v: Login) => Promise<Response>;
  history: History;
}

export const LoginPage = ({ loginAction, history }: LoginPage) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [userType, setUserType] = useState<UserType>(UserType.DRIVER);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState<CountryCode>("FR");
  const [createAccountForm, setCreateAccountForm] = useState(false);
  const [anAccountIsCreated, setAnAccountIsCreated] = useState(false);
  const dispatch = useThunkDispatch();

  const { showToast } = useToast();

  useEffect(() => {
    setCreateAccountForm(false);
  }, [userType]);

  const toggleOptions = [
    { value: UserType.DRIVER, text: "coursier" },
    { value: UserType.FACILITY, text: "pressing" },
    { value: UserType.ADMIN, text: "admin" }
  ];

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingAuth(true);

    const result = await loginAction({
      email,
      password,
      userType
    });

    setLoadingAuth(false);

    if (result.error) showToast(result.error, { type: TOAST_TYPE.ERROR });
    if (result.success) {
      showToast("Bienvenue");
      history.push("/orders");
    }
  };

  const register = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingRegister(true);

    // valid form data

    if (password !== repeatPassword) {
      return showToast("Les deux mots de passe saisies ne corresponds pas !");
    }

    let parsedPhoneNumber = parsePhoneNumberFromString(
      `+${phoneNumber}`,
      countryCode
    );

    const internationalNumber = parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
      : undefined;

    if (!internationalNumber) {
      return showToast("Le numéro de téléphone n'est pas valide !");
    }

    const driver = {
      password: password,
      phoneNumber: internationalNumber,
      email,
      name: lastName + " " + firstName
    } as DriverUpdate;

    setLoadingRegister(true);
    const response = await dispatch(registerDriver(driver));
    setLoadingRegister(false);
    if (response.success) {
      showToast("Compte créer effectuée avec succès");
      setCreateAccountForm(false);
      setAnAccountIsCreated(true);
      setEmail("");
      setPassword("");
    } else {
      showToast(
        response.error || "Veuillez  vérifier les infomrations entrées"
      );
    }
  };

  const registerFormIsValid = useMemo(() => {
    return !!phoneNumber && !!password && !!email && !!firstName && !!lastName;
  }, [phoneNumber, password, phoneNumber, lastName, firstName]);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (createAccountForm) {
      register(e);
    } else {
      login(e);
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmitForm}>
        <img src="./img/wast_logo.svg" alt="Wast" />
        <Toggle<UserType>
          defaultValue={UserType.DRIVER}
          options={toggleOptions}
          onChange={setUserType}
        />
        {createAccountForm && (
          <>
            <NameInput
              name="lastName"
              placeholder="NOM"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.currentTarget.value)
              }
              required
            />
            <NameInput
              name="firstName"
              placeholder="PRENOM"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.currentTarget.value)
              }
              required
            />
          </>
        )}
        <LoginInputEmail
          type="email"
          name="email"
          placeholder="ADRESSE MAIL"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
          required
        />
        {createAccountForm && (
          <InputPhone
            placeholder="TELEPHONE"
            value={phoneNumber}
            onChange={(phoneNumber, parameters): void => {
              setPhoneNumber(phoneNumber);
              setCountryCode(
                parameters.countryCode.toUpperCase() as CountryCode
              );
            }}
          />
        )}
        <LoginInputPassword
          type="password"
          placeholder="MOT DE PASSE"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.currentTarget.value)
          }
          required
        />
        {createAccountForm && (
          <LoginInputPassword
            type="password"
            placeholder="RESAISIR MOT DE PASSE"
            name="repeatPassword"
            value={repeatPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRepeatPassword(e.currentTarget.value)
            }
            required
          />
        )}
        <ButtonContainer>
          {createAccountForm ? (
            <>
              <RegisterButton
                type="submit"
                disabled={!registerFormIsValid || loadingRegister}
              >
                {loadingRegister
                  ? "création en cours..."
                  : "Créer votre compte"}
              </RegisterButton>
              <CancelButton
                type="button"
                onClick={() => setCreateAccountForm(false)}
              >
                Annuler
              </CancelButton>
            </>
          ) : (
            <>
              <LoginButton
                type="submit"
                disabled={!email || !password || loadingAuth}
              >
                {loadingAuth ? "Connexion en cours..." : "Se connecter"}
              </LoginButton>
              {userType === UserType.DRIVER && !anAccountIsCreated && (
                <RegisterButton
                  type="button"
                  onClick={() => setCreateAccountForm(true)}
                >
                  Créer votre compte maintenant
                </RegisterButton>
              )}
            </>
          )}
        </ButtonContainer>
      </form>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  loginAction
};

export default connect(null, mapDispatchToProps)(LoginPage);
