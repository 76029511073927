import styled from "styled-components";

export const TimeView = styled.div`
  height: 98px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TimeBlock = styled.button`
  width: 50%;
  height: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const TimeTitle = styled.span`
  text-align: center;
  font-size: 14px;
`;

export const TimeText = styled.span`
  font-size: 20px;
`;

export const DateText = styled.span`
  font-size: 12px;
`;

export const Input = styled.select`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid black;
  font-size: 18px;
  height: 48px;
  outline: none;
  width: 100%;
`;

export const InputContainer = styled.div`
  min-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 9px;
  justify-content: center;
  align-items: center;
`;

export const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 9px;
`;
