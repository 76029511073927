import { User, UserActionTypes, SET_USER, SET_USERS } from "./types";

interface GetUsersResponse {
  users: User[];
}

export const setUser = ({ type, ...values }: User): UserActionTypes => ({
  type: SET_USER,
  userType: type,
  ...values
});

export const setUsers = (users: User[]): UserActionTypes => ({
  type: SET_USERS,
  users
});
