import { DriverUpdate } from "../../typings/driver";
import {
  SET_DRIVERS,
  SET_DRIVER,
  DriversActionTypes,
  DriversState,
  DriverState
} from "./types";

const initialState: DriversState | DriverState = {
  allDrivers: [],
  driver: {} as DriverUpdate
};

const DriversReducer = (
  state = initialState,
  action: DriversActionTypes
): DriversState => {
  switch (action.type) {
    case SET_DRIVERS: {
      return {
        ...state,
        allDrivers: action.drivers
      };
    }
    case SET_DRIVER: {
      return {
        ...state,
        driver: action.driver
      };
    }

    default:
      return state;
  }
};

export default DriversReducer;
