import React, { ReactNode, useEffect } from "react";
import { Backdrop, Close, ModalWrapper, ModalContainer } from "./style";

interface ModalProps {
  children?: ReactNode;
  closeModal: () => void;
  open: boolean;
  required?: boolean;
}

export const RangePickerModal = ({
  children,
  closeModal,
  open,
  required = false
}: ModalProps): JSX.Element => {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];

    if (htmlElement && open) {
      htmlElement.classList.add("no-scroll");
    } else {
      htmlElement.classList.remove("no-scroll");
    }
  }, [open]);

  return (
    <ModalContainer>
      {open && (
        <Backdrop>
          <ModalWrapper>
            {!required && (
              <Close
                onClick={(): void => {
                  if (closeModal) closeModal();
                }}
              >
                X
              </Close>
            )}
            {children}
          </ModalWrapper>
        </Backdrop>
      )}
    </ModalContainer>
  );
};
