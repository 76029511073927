import React, { useEffect } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import Layout from "../layout";
import DataTable from "react-data-table-component";
import { AppState } from "../../store";
import { Button, Editbutton } from "./style";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { getDrivers } from "../../store/drivers/actions";
import { Driver } from "../../typings/driver";

const columns = [
  {
    name: "Nom",
    selector: "name",
    sortable: true
  },
  {
    name: "E-mail",
    selector: "email",
    sortable: true
  },
  {
    name: "Numéro de téléphone",
    selector: "phoneNumber",
    sortable: true
  },
  {
    name: "Status",
    selector: "status",
    cell: (row: Driver) => (row.status === "ACTIVE" ? "Actif" : "Désativé")
  },
  {
    name: "action",
    selector: "id",
    cell: (row: Driver) => {
      return (function Actions() {
        return (
          <>
            <Link to={`/drivers/edit/${row.id}`}>
              <Editbutton>
                <FaEdit />
                Modifier
              </Editbutton>
            </Link>
          </>
        );
      })();
    }
  }
];

const Drivers = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getDrivers());
      setPending(false);
    };

    fetchData();
  }, [dispatch]);

  const data: Driver[] = useSelector<AppState, Driver[]>(
    state => state.drivers.allDrivers
  );

  return (
    <Layout title="Services" showSearchBag={false}>
      <div className="App">
        <Link to="/drivers/edit">
          <Button>Ajouter un nouveau coursier</Button>
        </Link>
        <DataTable
          title="Coursiers"
          columns={columns}
          data={data}
          defaultSortField="title"
          pagination
          responsive
          progressPending={pending}
        />
      </div>
    </Layout>
  );
};

export default Drivers;
