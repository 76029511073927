import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Layout from "../layout";
import DataTable from "react-data-table-component";
import ReactTooltip from "react-tooltip";
import { getTasks, postTask } from "../../store/tasks/actions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store";
import { ActionButton } from "./style";
import { FaEdit, FaPlus } from "react-icons/fa";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { Modal } from "../modal/";
import { useModal } from "../modal/useModal";
import { TasksModal } from "../tasks-modal";
import moment from "moment";
import { OnfleetTask, Tasks } from "../../typings/tasks";
import { useToast } from "../toast";

const DeliveryTask = () => {
  const { isShown, toggle } = useModal();

  const { showToast } = useToast();
  const [inprogress, setInprogress] = React.useState(false);

  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const history = useHistory();
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getTasks());
      setPending(false);
    };

    fetchData();
  }, [dispatch]);

  const [selectedTask, setSelectedTask] = React.useState({} as OnfleetTask);

  const data: Tasks[] = useSelector<AppState, Tasks[]>(
    state => state.Tasks.allTasks as Tasks[]
  );

  data.forEach(element => {
    if (element.pickupTask && element.dropoffTask) {
      element.comment = "-";
    } else if (!element.dropoffTask) {
      element.comment = "Erreur : aucune tâche de livraison assignée.";
    } else if (!element.pickupTask) {
      element.comment = "Erreur : aucune tâche de récupération assignée.";
    }
  });

  const columns = [
    {
      name: "Date de création",
      selector: "createdAt",
      sortable: true,
      width: "10%"
    },
    {
      name: "Ref",
      selector: "orderReference",
      sortable: true,
      width: "10%",
      cell: row => {
        return (function Actions() {
          return (
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/order/${row.orderReference}`);
              }}
            >
              {row.orderReference}
            </a>
          );
        })();
      }
    },
    {
      name: "Service",
      selector: "service",
      sortable: true,
      width: "6%"
    },
    {
      name: "Nom",
      selector: "recipients[0].name",
      sortable: true,
      width: "10%"
    },
    {
      name: "T. Réc.",
      selector: "pickupTask.id",
      cell: row => {
        return (function Actions() {
          return row.pickupTask ? (
            <div>
              <a
                data-tip
                data-for={row.pickupTask.id}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://onfleet.com/dashboard#/table?taskEdit=false&open=task&taskId=" +
                  row.pickupTask.id
                }
              >
                Lien
              </a>
              <ReactTooltip id={row.pickupTask.id} place="top" effect="solid">
                {row.pickupTask.notes}
              </ReactTooltip>
            </div>
          ) : (
            <ActionButton
              onClick={() => {
                const onfleetTask = row.dropoffTask as OnfleetTask;
                const task = {
                  destination: onfleetTask.destination,
                  recipients: onfleetTask.recipients,
                  completeAfter: onfleetTask.completeAfter,
                  completeBefore: onfleetTask.completeBefore,
                  pickupTask: !onfleetTask.pickupTask,
                  notes: onfleetTask.notes,
                  metadata: onfleetTask.metadata.filter(
                    m => m.name != "paymentStatus"
                  )
                } as OnfleetTask;
                setSelectedTask(task);
                // sessionStorage.setItem("task", JSON.stringify(task));
                toggle();
              }}
            >
              <FaPlus></FaPlus> {inprogress ? "En cours..." : "ajouter"}
            </ActionButton>
          );
        })();
      }
    },
    {
      name: "Adresse Réc.",
      selector: d =>
        d.pickupTask ? d.pickupTask.destination.address : d.pickupTask,
      cell: row => {
        return (function Actions() {
          return row.pickupTask
            ? row.pickupTask.destination.address.country +
                "-" +
                row.pickupTask.destination.address.city +
                "-" +
                row.pickupTask.destination.address.state +
                "-" +
                row.pickupTask.destination.address.street
            : "";
        })();
      },
      sortable: true,
      width: "12%"
    },
    {
      name: "T. liv.",
      selector: "dropoffTask.id",
      cell: row => {
        return (function Actions() {
          return row.dropoffTask ? (
            <div>
              <a
                data-tip
                data-for={row.dropoffTask.id}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://onfleet.com/dashboard#/table?taskEdit=false&open=task&taskId=" +
                  row.dropoffTask.id
                }
              >
                Lien
              </a>
              <ReactTooltip id={row.dropoffTask.id} place="top" effect="solid">
                {row.dropoffTask.notes}
              </ReactTooltip>
            </div>
          ) : (
            <ActionButton
              onClick={() => {
                const onfleetTask = row.pickupTask as OnfleetTask;
                const task = {
                  destination: onfleetTask.destination,
                  recipients: onfleetTask.recipients,
                  completeAfter: onfleetTask.completeAfter,
                  completeBefore: onfleetTask.completeBefore,
                  pickupTask: !onfleetTask.pickupTask,
                  notes: onfleetTask.notes,
                  metadata: onfleetTask.metadata.filter(
                    m => m.name != "paymentStatus"
                  )
                } as OnfleetTask;
                setSelectedTask(task);
                // sessionStorage.setItem("task", JSON.stringify(task));
                toggle();
              }}
            >
              <FaPlus></FaPlus> {inprogress ? "En cours..." : "ajouter"}
            </ActionButton>
          );
        })();
      }
    },
    {
      name: "Adresse Liv.",
      selector: d =>
        d.dropoffTask ? d.dropoffTask.destination.address : d.dropoffTask,
      cell: row => {
        return (function Actions() {
          return row.dropoffTask
            ? row.dropoffTask.destination.address.country +
                "-" +
                row.dropoffTask.destination.address.city +
                "-" +
                row.dropoffTask.destination.address.state +
                "-" +
                row.dropoffTask.destination.address.street
            : "";
        })();
      },
      sortable: true,
      width: "12%"
    },
    {
      name: "Commentaire",
      selector: "comment",
      sortable: true,
      width: "12%"
    },
    {
      name: "Statut de paiement",
      selector: "paymentStatus",
      sortable: true,
      width: "12%"
    }
  ];

  const handleSubmit = useCallback(async () => {
    setInprogress(true);
    const taskToCreate = {
      destination: {
        id: selectedTask.destination.id
      },
      recipients: selectedTask.recipients.map(r => {
        return { id: r.id };
      }),
      completeAfter: selectedTask.completeAfter,
      completeBefore: selectedTask.completeBefore,
      pickupTask: selectedTask.pickupTask,
      notes: selectedTask.notes,
      metadata: selectedTask.metadata.filter(m =>
        ["service", "commande", "order"].includes(m.name)
      )
    } as OnfleetTask;
    let response = await dispatch(postTask(taskToCreate));
    if (response.success) {
      showToast("Tache éffectué avec succées ");
      setInprogress(false);
      setPending(true);
      await dispatch(getTasks());
      setPending(false);
    } else {
      showToast("Une erreur est produite lors de l'ajout de cette tache ");
      setInprogress(false);
    }
    setInprogress(false);
  }, [dispatch]);

  const onConfirmAddTask = () => {
    console.log("onConfirmAddTask");
    toggle();
    handleSubmit();
  };

  return (
    <Layout title="Taches de livraison " showSearchBag={false}>
      <div className="App">
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText=""
          modalContent={
            <TasksModal onConfirm={onConfirmAddTask} task={selectedTask} />
          }
        />
        <DataTable
          title="Taches de livraison"
          columns={columns}
          data={data}
          pagination={false}
          responsive
          progressPending={pending}
          defaultSortField="createdAt"
          defaultSortAsc={false}
        />
      </div>
    </Layout>
  );
};

export default DeliveryTask;
