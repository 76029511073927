import React from "react";
import { AuthState } from "../../store/auth/types";
import { AppState } from "../../store";
import { UserType } from "../../store/user/types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { slide as BurgerMenu } from "react-burger-menu";

import { menu as styles } from "./style";

const Menu = ({ role }: AuthState) => {
  let burgerDriverMenu = (
    <BurgerMenu styles={styles} width={300} value={role}>
      <Link className="menu-item" to="/orders">
        Accueil
      </Link>
    </BurgerMenu>
  );
  let burgerFacilityMenu = (
    <BurgerMenu styles={styles} width={300} value={role}>
      <Link className="menu-item" to="/orders">
        Accueil
      </Link>
      <Link className="menu-item" to="/orders/today">
        Commande du jour
      </Link>
      <Link className="menu-item" to="/orders/tomorrow">
        Commandes du lendemain
      </Link>
    </BurgerMenu>
  );
  let burgerAdminMenu = (
    <BurgerMenu styles={styles} width={300} value={role}>
      <Link className="menu-item" to="/orders">
        Accueil
      </Link>
      <Link className="menu-item" to="/deliveryTask">
        Taches de livraison
      </Link>
      <Link className="menu-item" to="/orders/today">
        Commande du jour
      </Link>
      <Link className="menu-item" to="/orders/tomorrow">
        Commandes du lendemain
      </Link>
      <Link className="menu-item" to="/availability">
        Disponibilités
      </Link>
      <Link className="menu-item" to="/operationAreas">
        Zones géographiques
      </Link>
      <Link className="menu-item" to="/serviceclasses">
        Services
      </Link>
      <Link className="menu-item" to="/timeslots">
        Tranches de temps
      </Link>
      <Link className="menu-item" to="/holidays">
        Vacances
      </Link>
      <Link className="menu-item" to="/drivers" style={{ width: "100%" }}>
        Coursiers
      </Link>
    </BurgerMenu>
  );

  if (role == UserType.DRIVER.toString().toLowerCase()) {
    return burgerDriverMenu;
  } else if (role == UserType.FACILITY.toString().toLowerCase()) {
    return burgerFacilityMenu;
  }

  return burgerAdminMenu;
};

const mapStateToProps = (state: AppState) => state.auth;

export default connect(mapStateToProps)(Menu);
