import { Driver, DriverUpdate } from "../../typings/driver";

export const SET_DRIVERS = "SET_DRIVERS";
export const SET_DRIVER = "SET_DRIVER";

interface SetDrivers {
  type: typeof SET_DRIVERS;
  drivers: Driver[];
}

interface SetDriver {
  type: typeof SET_DRIVER;
  driver: DriverUpdate;
}

export type DriversActionTypes = SetDrivers | SetDriver;

export interface DriversState {
  allDrivers: Driver[];
  driver: DriverUpdate;
}

export interface DriverState {
  driver: DriverUpdate;
}
