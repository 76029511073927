import { AppState } from "../index";
import Errors from "../../constants/errors.json";
import { get, HttpResponse, post, remove, put } from "../../utils/http";
import {
  ActionResponse,
  Comment,
  OrderType,
  OrdersActionTypes,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_BAG,
  CLEAR_SEARCHED,
  CLEAR_SEARCHED_ORDER,
  SET_PAGE,
  SET_TOTAL_PAGES,
  SET_BAG_TYPE,
  SET_CURRENT_BAG,
  SET_NEW_COMMENT,
  ADD_ORDERS,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  SET_ORDERS,
  SET_SEARCHED_ORDER,
  SET_ORDER_FILTER_DATES,
  Item,
  Option,
  SET_PICKUP_RANGE,
  SET_DROP_OFF_RANGE,
  SET_CURRENT_OPTION,
  Range,
  SET_OPTIONS
} from "./types";
import { BagTypeType } from "../../store/orders/types";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Order } from "../../types/order";

interface SetOrders {
  orders: OrderType[];
  timestamp?: number;
}

interface AddOrders {
  orders: OrderType[];
  timestamp?: number;
}

interface SetOrdersResponse {
  orders: OrderType[];
  total: number;
  perPage: number;
}

interface GetOptionsResponse {
  serviceClasses: Option[];
}

export const setOrders = ({ orders }: SetOrders): OrdersActionTypes => ({
  type: SET_ORDERS,
  orders
});

export const setSearchedOrder = (order: OrderType): OrdersActionTypes => ({
  type: SET_SEARCHED_ORDER,
  order
});

export const clearSearched = (): OrdersActionTypes => ({
  type: CLEAR_SEARCHED
});

export const clearBag = (): OrdersActionTypes => ({
  type: CLEAR_BAG
});

export const clearSearchedOrder = (): OrdersActionTypes => ({
  type: CLEAR_SEARCHED_ORDER
});

export const actionRemoveBag = (orderId: string, barcode: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  let response!: HttpResponse<OrderType>;
  const endpoint = "/orders/" + orderId + "/bag/" + barcode + "/remove";

  const {
    orders: { orders }
  } = getState();

  try {
    response = await remove(endpoint, getState());
  } catch (e) {
    const errorText =
      401 === e.status ? Errors.invalidCredentials : Errors.serverError;

    return {
      success: false,
      error: errorText
    };
  }

  if (response && response.parsedBody) {
    const newOrder = response.parsedBody;

    dispatch(clearBag());
    dispatch(setSearchedOrder(newOrder));

    for (let iterator = 0; iterator < orders.length; iterator++) {
      if (orders[iterator].id === newOrder.id) {
        orders[iterator] = newOrder;

        dispatch(setOrders({ orders }));
        break;
      }
    }

    return {
      success: true
    };
  }

  return {
    success: true
  };
};

export const changeDates = (value: string): OrdersActionTypes => ({
  type: SET_ORDER_FILTER_DATES,
  filterDates: value
});

export const setCurrentBag = (
  barcode: string,
  itemization: Item[]
): OrdersActionTypes => ({
  type: SET_CURRENT_BAG,
  barcode,
  itemization
});

export const addOrders = ({ orders }: AddOrders): OrdersActionTypes => ({
  type: ADD_ORDERS,
  orders
});

export const setPage = (page: number): OrdersActionTypes => ({
  type: SET_PAGE,
  page
});

export const setTotalPages = (page: number): OrdersActionTypes => ({
  type: SET_TOTAL_PAGES,
  page
});

export const addProduct = (
  productId: string,
  quantity = 0
): OrdersActionTypes => ({
  type: ADD_PRODUCT,
  productId,
  quantity
});

export const removeProduct = (
  productId: string,
  quantity = 0
): OrdersActionTypes => ({
  type: REMOVE_PRODUCT,
  productId,
  quantity
});

export const setItemizationBagType = (bagType: string): OrdersActionTypes => ({
  type: SET_BAG_TYPE,
  bagType
});

interface SetNewComment {
  orderId: string;
  newComment: Comment;
}

export const setNewComment = ({
  orderId,
  newComment
}: SetNewComment): OrdersActionTypes => ({
  type: SET_NEW_COMMENT,
  orderId,
  newComment
});

interface UpdateComment {
  orderId: string;
  comment: Comment;
}

export const updateComment = ({
  orderId,
  comment
}: UpdateComment): OrdersActionTypes => ({
  type: UPDATE_COMMENT,
  orderId,
  comment
});

interface DeleteComment {
  orderId: string;
  commentId: string;
}

export const deleteComment = ({
  orderId,
  commentId
}: DeleteComment): OrdersActionTypes => ({
  type: DELETE_COMMENT,
  orderId,
  commentId
});

export const searchBarcode = (barcode: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  let response!: HttpResponse<OrderType>;

  try {
    response = await get("/orders/search?barcode=" + barcode, getState());
  } catch (e) {
    const errorText =
      401 === e.status ? Errors.invalidCredentials : Errors.serverError;

    return {
      success: false,
      error: errorText
    };
  }

  if (response && response.parsedBody) {
    const order = response.parsedBody;
    dispatch(setSearchedOrder(order));

    const currentBag = order.itemization.bags.find(
      bag => bag.barcode === barcode
    );

    if (currentBag) {
      dispatch(setCurrentBag(barcode, currentBag.itemization));
    }
  }

  return {
    success: true
  };
};

export const getOrders = (textSearch?: string, concat?: boolean) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  const {
    orders: { page, filterDates }
  } = getState();
  const filterDatesQueryParam = filterDates ? "&dates=" + filterDates : "";
  let response!: HttpResponse<SetOrdersResponse>;
  const endpoint = textSearch
    ? "/orders/search/" +
      encodeURIComponent(textSearch) +
      "?page=" +
      page +
      filterDatesQueryParam
    : "/orders?page=" + page + filterDatesQueryParam;

  try {
    response = await get(endpoint, getState());
  } catch (e) {
    const errorText =
      401 === e.status ? Errors.invalidCredentials : Errors.serverError;

    return {
      success: false,
      error: errorText
    };
  }

  if (response && response.parsedBody) {
    const { orders, total, perPage } = response.parsedBody;
    if (concat) {
      dispatch(addOrders({ orders }));
    } else {
      dispatch(setOrders({ orders }));
    }
    dispatch(setTotalPages(Math.ceil(total / perPage)));
  }

  return {
    success: true
  };
};

export const saveBagItemization = (barcode: string | null) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  let response!: HttpResponse<OrderType>;
  let {
    orders: {
      currentBagBarcode,
      currentBagType,
      currentOrder,
      itemization,
      orders
    }
  } = getState();

  // I need this in order to prevent a glitch in the UI when adding a bag
  if (barcode !== null) {
    currentBagBarcode = barcode;
  }

  try {
    if (!currentOrder) {
      return {
        success: false,
        error: "Pas de commande sélectionnée"
      };
    }
    const apiItemization = Object.keys(itemization).map(product => ({
      product,
      quantity: itemization[product]
    }));

    response = await post(
      `/orders/${currentOrder.id}/bag/saveItemization`,
      {
        barcode: currentBagBarcode,
        type: currentBagType ? currentBagType : BagTypeType.DRY_CLEANING,
        itemization: barcode === null ? apiItemization : []
      },
      getState()
    );
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.parsedBody) {
    const newOrder = response.parsedBody;

    dispatch(clearBag());
    dispatch(setSearchedOrder(newOrder));

    for (let iterator = 0; iterator < orders.length; iterator++) {
      if (orders[iterator].id === newOrder.id) {
        orders[iterator] = newOrder;

        dispatch(setOrders({ orders }));
        break;
      }
    }

    return {
      success: true
    };
  }
};

export const commentOrder = (id: string, comment: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  let response!: HttpResponse<Comment>;

  try {
    response = await post(`/orders/${id}/comments`, { comment }, getState());
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.parsedBody) {
    const newComment = response.parsedBody;
    dispatch(setNewComment({ orderId: id, newComment }));
  }

  return {
    success: true
  };
};

export const updateOrderComment = (
  id: string,
  commentId: string,
  comment: string
) => async (dispatch: ReduxDispatch, getState: () => AppState) => {
  let response!: HttpResponse<Comment>;

  try {
    response = await put(
      `/orders/${id}/comments/${commentId}`,
      { comment },
      getState()
    );
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.parsedBody) {
    const newComment = response.parsedBody;
    dispatch(updateComment({ orderId: id, comment: newComment }));
  }

  return {
    success: true
  };
};

export const deleteOrderComment = (id: string, commentId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
) => {
  let response!: HttpResponse<Comment>;

  try {
    response = await remove(`/orders/${id}/comments/${commentId}`, getState());
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.parsedBody) {
    dispatch(deleteComment({ orderId: id, commentId }));
  }

  return {
    success: true
  };
};

interface ChangeStatusInterface {
  orderId: string;
}

export const endItemization = () => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  const {
    orders: { currentOrder, orders }
  } = getState();
  if (!currentOrder) {
    return {
      success: false,
      error: "Commande introuvable"
    };
  }
  const url = `/orders/${currentOrder.id}/itemization/complete`;
  let response: HttpResponse<OrderType>;

  try {
    response = await post(url, {}, getState());
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.ok) {
    const newOrder = response.parsedBody!;

    newOrder.itemization = {
      bags: newOrder.itemization.bags,
      status: newOrder.itemization.status
    };
    dispatch(setSearchedOrder(newOrder));

    for (let iterator = 0; iterator < orders.length; iterator++) {
      if (orders[iterator].id === newOrder!.id) {
        orders[iterator] = newOrder!;

        dispatch(setOrders({ orders }));
        break;
      }
    }

    return { success: true };
  }

  return {
    success: false,
    error: Errors.unknownError
  };
};

export const endCleaning = (
  { orderId }: ChangeStatusInterface,
  redirectAfterCall: boolean
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  const {
    orders: { orders }
  } = getState();

  redirectAfterCall =
    redirectAfterCall !== undefined ? redirectAfterCall : true;
  const url = `/orders/${orderId}/cleaning/complete`;
  let response: HttpResponse<OrderType>;

  try {
    response = await post(url, {}, getState());
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.ok) {
    const newOrder = response.parsedBody;

    // check if I should redirect -> this is not the case when using the barcode scanner on the homepage
    if (redirectAfterCall) {
      dispatch(setSearchedOrder(response.parsedBody!));
    }

    for (let iterator = 0; iterator < orders.length; iterator++) {
      if (orders[iterator].id === newOrder!.id) {
        orders[iterator] = newOrder!;

        dispatch(setOrders({ orders: [] }));
        dispatch(setOrders({ orders }));
        break;
      }
    }

    return { success: true };
  }

  return {
    success: false,
    error: Errors.unknownError
  };
};

export const startRecleaning = (
  { orderId }: ChangeStatusInterface,
  redirectAfterCall: boolean
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  const {
    orders: { orders }
  } = getState();

  redirectAfterCall =
    redirectAfterCall !== undefined ? redirectAfterCall : true;
  const url = `/orders/${orderId}/cleaning/recleaning`;
  let response: HttpResponse<OrderType>;

  try {
    response = await post(url, {}, getState());
  } catch (e) {
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  if (response && response.ok) {
    const newOrder = response.parsedBody;

    // check if I should redirect -> this is not the case when using the barcode scanner on the homepage
    if (redirectAfterCall) {
      dispatch(setSearchedOrder(response.parsedBody!));
    }

    for (let iterator = 0; iterator < orders.length; iterator++) {
      if (orders[iterator].id === newOrder!.id) {
        orders[iterator] = newOrder!;

        dispatch(setOrders({ orders: [] }));
        dispatch(setOrders({ orders }));
        break;
      }
    }

    return { success: true };
  }

  return {
    success: false,
    error: Errors.unknownError
  };
};

export const searchOrderByBag = (barcode: string) => async (
  dispatch: ReduxDispatch
) => {
  return await dispatch(searchBarcode(barcode));
};

export const setPickupRange = (pickupRange: Range): OrdersActionTypes => ({
  type: SET_PICKUP_RANGE,
  pickupRange
});

export const setDropOffRange = (dropOffRange: Range): OrdersActionTypes => ({
  type: SET_DROP_OFF_RANGE,
  dropOffRange
});

export const setCurrentOption = (currentOption: Option): OrdersActionTypes => ({
  type: SET_CURRENT_OPTION,
  currentOption
});

export const setOptions = (options: Option[]): OrdersActionTypes => ({
  type: SET_OPTIONS,
  options
});

export const getOptions = (): ThunkAction<
  Promise<HttpResponse<GetOptionsResponse>>,
  AppState,
  {},
  AnyAction
> => async (
  dispatch: ReduxDispatch,
  getState
): Promise<HttpResponse<GetOptionsResponse>> => {
  try {
    const response = await get<GetOptionsResponse>(
      "/service-classes",
      getState()
    );

    if (response.parsedBody) {
      const { serviceClasses } = response.parsedBody;
      serviceClasses.sort((a: Option, b: Option) =>
        a.sortOrder < b.sortOrder ? -1 : 1
      );

      dispatch(setOptions(serviceClasses));
      dispatch(setCurrentOption(serviceClasses[0]));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const rescheduleOrderRequest = (order: Order) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response: HttpResponse<Order>;

  const state = getState();

  const { pickupRange, dropOffRange } = state.orders;

  let body = {};

  if (pickupRange)
    body = {
      pickupTime: {
        startTime: pickupRange.startDate.toISOString(),
        endTime: pickupRange.endDate.toISOString()
      }
    };
  if (dropOffRange)
    body = {
      ...body,
      dropoffTime: {
        startTime: dropOffRange.startDate.toISOString(),
        endTime: dropOffRange.endDate.toISOString()
      }
    };

  try {
    response = await post(`/orders/${order.id}/reschedule`, body, getState());
  } catch (e) {
    console.error("error", e);
    return {
      success: false,
      error: 401 === e.status ? Errors.invalidCredentials : Errors.serverError
    };
  }

  return {
    success: true
  };
};
