import styled from "styled-components";

const BORDER_RADIUS = "0px";

export const Label = styled.label`
  font-size: 0.8em;
  font-weight: bold;
`;

export const PhoneInputWrapper = styled.div`
  padding: 1px;
  width: 100%;
  display: block;
  background: black;
  .react-tel-input .phone-input_button {
    background-color: #fff;

    .selected-flag {
      border: none;
      border-radius: ${BORDER_RADIUS} 0 0 ${BORDER_RADIUS};
    }
  }

  .react-tel-input input.phone-input_input {
    border-radius: ${BORDER_RADIUS};
    border: none;
    width: 100%;
  }
`;
