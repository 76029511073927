import { Dayjs } from "dayjs";
import { Address } from "../../types/address";

export const SET_PAGE = "SET_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SET_BAG_TYPE = "SET_BAG_TYPE";
export const SET_CURRENT_BAG = "SET_CURRENT_BAG";
export const SET_ORDERS = "SET_ORDERS";
export const ADD_ORDERS = "ADD_ORDERS";
export const SET_NEW_COMMENT = "SET_NEW_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const SET_SEARCHED_ORDER = "SET_SEARCHED_ORDER";
export const CLEAR_BAG = "CLEAR_BAG";
export const CLEAR_SEARCHED = "CLEAR_SEARCHED";
export const CLEAR_SEARCHED_ORDER = "CLEAR_SEARCHED_ORDER";
export const SET_ORDER_FILTER_DATES = "SET_ORDER_FILTER_DATES";
export const SET_PICKUP_RANGE = "SET_PICKUP_RANGE";
export const SET_DROP_OFF_RANGE = "SET_DROP_OFF_RANGE";
export const SET_CURRENT_OPTION = "SET_CURRENT_OPTION";
export const SET_OPTIONS = "SET_OPTIONS";

export interface Range {
  isDefault: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  startTime: string;
  endTime: string;
  dropOffDelay?: number;
}

interface SetOrders {
  type: typeof SET_ORDERS;
  orders: OrderType[];
}

interface AddOrders {
  type: typeof ADD_ORDERS;
  orders: OrderType[];
}

interface AddProduct {
  type: typeof ADD_PRODUCT;
  productId: string;
  quantity: number;
}

interface SetBagType {
  type: typeof SET_BAG_TYPE;
  bagType: string;
}

interface RemoveProduct {
  type: typeof REMOVE_PRODUCT;
  productId: string;
  quantity: number;
}

interface SetPage {
  type: typeof SET_PAGE;
  page: number;
}

interface SetTotalPages {
  type: typeof SET_TOTAL_PAGES;
  page: number;
}

interface ClearSearched {
  type: typeof CLEAR_SEARCHED;
}

interface ClearBag {
  type: typeof CLEAR_BAG;
}

interface ClearSearchedOrder {
  type: typeof CLEAR_SEARCHED_ORDER;
}

interface SetCurrentBag {
  type: typeof SET_CURRENT_BAG;
  barcode: string;
  itemization: Item[];
}

interface SetSearchedOrder {
  type: typeof SET_SEARCHED_ORDER;
  order: OrderType;
}

interface SetNewComment {
  type: typeof SET_NEW_COMMENT;
  orderId: string;
  newComment: Comment;
}

interface UpdateComment {
  type: typeof UPDATE_COMMENT;
  orderId: string;
  comment: Comment;
}

interface DeleteComment {
  type: typeof DELETE_COMMENT;
  orderId: string;
  commentId: string;
}

interface SetOrderFilterDates {
  type: typeof SET_ORDER_FILTER_DATES;
  filterDates: string;
}

interface SetPickupRange {
  type: typeof SET_PICKUP_RANGE;
  pickupRange: Range;
}

interface SetDropOffRange {
  type: typeof SET_DROP_OFF_RANGE;
  dropOffRange: Range;
}

interface SetCurrentOption {
  type: typeof SET_CURRENT_OPTION;
  currentOption: Option;
}

interface SetOptions {
  type: typeof SET_OPTIONS;
  options: Option[];
}

export type OrdersActionTypes =
  | AddProduct
  | ClearBag
  | ClearSearched
  | ClearSearchedOrder
  | RemoveProduct
  | SetBagType
  | SetCurrentBag
  | SetOrders
  | AddOrders
  | SetPage
  | SetTotalPages
  | SetNewComment
  | SetSearchedOrder
  | SetOrderFilterDates
  | UpdateComment
  | DeleteComment
  | SetPickupRange
  | SetDropOffRange
  | SetCurrentOption
  | SetOptions;

export enum CleaningStatus {
  WAITING = "AWAITING_ITEMIZATION",
  BEING_PROCESSED = "IN_PROGRESS",
  READY_FOR_DROPOFF = "COMPLETED",
  BEING_RECLEANED = "RECLEANING"
}

export enum OrderStatus {
  PICKUP_SCHEDULED = "PICKUP_SCHEDULED",
  AWAITING_PICKUP = "AWAITING_PICKUP",
  NOSHOW_PICKUP = "NOSHOW_PICKUP",
  FAILED_PICKUP = "FAILED_PICKUP",
  ITEMIZATION = "ITEMIZATION",
  CLEANING = "CLEANING",
  RECLEANING = "RECLEANING",
  READY = "READY",
  AWAITING_DEPOSIT = "AWAITING_DEPOSIT",
  REACH_WORKSHOP = "REACH_WORKSHOP",
  AWAITING_DROPOFF = "AWAITING_DROPOFF",
  NOSHOW_DROPOFF = "NOSHOW_DROPOFF",
  FAILED_DROPOFF = "FAILED_DROPOFF",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED"
}

export type Cleaning = {
  status: CleaningStatus;
};

export type Comment = {
  id: string;
  comment: string;
  createdBy: string;
  createdAt: Date;
};

export interface Option {
  id: string;
  name: string;
  minOrderValue: number;
  pickupLeadTime: number;
  timeslotDuration: number;
  sortOrder: number;
  price: number;
}

export interface Item {
  product: string;
  quantity: number;
}

export enum BagTypeType {
  WASH_AND_FOLD = "WASH_AND_FOLD",
  DRY_CLEANING = "DRY_CLEANING"
}

export interface BagType {
  barcode: string;
  type: BagTypeType;
  itemization: Item[];
}

export type ItemizationType = {
  bags: BagType[];
  status: string;
};

export interface TimeRange {
  startTime: Date;
  endTime: Date;
}

export interface Corporate {
  name: string;
}

export interface OrderType {
  id: string;
  cleaning: Cleaning;
  comments: Comment[];
  customer: string;
  dropoffTime: TimeRange;
  dropoffDriver?: string;
  itemization: ItemizationType;
  notes?: string;
  pickupTime: TimeRange;
  serviceClass: string;
  isItemizationBlocked: boolean;
  corporate: Corporate;
  status: OrderStatus;
}

export interface Position {
  lat: number;
  lng: number;
}

export interface AddressComponents {
  number?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

export interface ExtendedGoogleMapsPlace {
  coordinates: Position;
  formattedAddress?: string;
  addressComponents?: AddressComponents;
}

export interface OrdersState {
  currentBagBarcode: string | null;
  currentBagType: string | null;
  currentOrder: OrderType | null;
  orders: OrderType[];
  searched: boolean;
  itemization: { [key: string]: number };
  page: number;
  totalPages: number;
  filterDates: string | null;
  pickupRange?: Range;
  dropOffRange?: Range;
  currentOption?: Option;
  address?: ExtendedGoogleMapsPlace;
  options: Option[];
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export interface OrderSort {
  property: keyof OrderType;
  direction: SortDirection;
}

export interface ActionResponse {
  success: boolean;
  error?: string;
}
