export enum UserType {
  DRIVER = "DRIVER",
  FACILITY = "FACILITY",
  ADMIN = "ADMIN",
  NULL = "NULL"
}

export interface User {
  email: string;
  id: string;
  name: string;
  type: UserType;
}

export interface UserState extends User {
  drivers: User[];
}

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_USERS = "SET_USERS";

interface SetUser {
  type: typeof SET_USER;
  email: string;
  id: string;
  name: string;
  userType: UserType;
}

interface RemoveUser {
  type: typeof REMOVE_USER;
}

interface SetServiceClasses {
  type: typeof SET_USERS;
  users: User[];
}

export type UserActionTypes = SetUser | RemoveUser | SetServiceClasses;
